<template>
  <AuthWrapper>
    <v-card-text class="mt-5">
      <v-row align-content="center" justify="center">
        <v-col cols="12">
          <div class="forgot-top d-flex flex-column my-4">
            <v-btn class="text-center mx-auto" color="secondary" dark fab large>
              <v-icon dark> mdi-lock</v-icon>
            </v-btn>
            <h3 class="text-h3 text-center mb-3 mt-3">Reset Password</h3>
          </div>
          <v-form v-model="forgotFormValidated" @submit.prevent="forgot">
            <v-text-field
              v-model="forgotForm.email"
              :error-messages="emailError"
              :rules="validator.email"
              outlined
              placeholder="Email"
            ></v-text-field>
            <v-btn :disabled="!forgotFormValidated" block color="secondary" type="submit">Send Reset Mail</v-btn>
          </v-form>
          <div class="d-flex mt-3 mb-3 justify-space-between">
            <p>
              <router-link :to="{name: 'Login'}">Back to Login</router-link>
            </p>
            <p> Don't have Account?
              <router-link :to="{name: 'Register'}">Sign up</router-link>
            </p>
          </div>

          <div class="d-flex mx-auto flex-column">
            <h4 class="text-h6 text-center my-5 d-block">OR</h4>
            <v-btn :to="{name : 'Home'}" outlined>Back To Home</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </AuthWrapper>
</template>

<script>

import Auth from '@/api/Auth';
import Toast from '@/utils/Toast';
import AuthWrapper from '@/views/auth/AuthWrapper';

export default {
  name: 'Login',
  components: { AuthWrapper },
  data: () => ({
    forgotFormValidated: false,
    forgotForm: {
      email: '',
    },
    errors: {
      email: [],
    },
    validator: {
      email: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }
  }),
  computed: {

    emailError() {
      return this.errors?.email?.length ? this.errors.email[0] : '';
    },
  },
  methods: {
    async forgot() {
      let response = await Auth.forgot({ email: this.forgotForm.email });
      Toast.success(response.data.message);
    }
  }
};
</script>

<style lang="scss">
.forgot-page-content {
  height: 100%;
  min-height: 100%;
}

.forgot-left {
  background-image: url('../../assets/images/bg/login-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
